const axios = require('axios');
import '../styles/index.scss';

const framework = {};

const els = {
    preloader: document.querySelector('.preloader'),
    preloaderLoader: document.querySelector('.preloader-loader'),
    page: document.querySelector('.page'),
    devices: document.querySelectorAll('.page-devices__device'),
    slides: document.querySelectorAll('.page-bg__slide'),
    dots: document.querySelectorAll('.page-dots li'),
    downloadBtn: document.querySelector('.page-download__button'),
    closeBtn: document.querySelector('.popup-window__close'),
    form: document.querySelector('#js-send-form'),
    popup: document.querySelector('.popup'),
    landscapePopup: document.querySelector('.popup-landscape')
};

const config = {
    interval: 3000,
    preloaderInterval: 3100
};

const isMobile = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};


let playInterval = () => {
};

framework.init = () => {
    els.preloaderLoader.addEventListener("animationend", (e) => {
        els.preloader.classList.add('hidden');
        framework.autoplay();
    }, false);
    framework.popup();
    framework.dots();
};

framework.dots = () => {
    for (let i = 0; i < els.dots.length; i++) {
        els.dots[i].addEventListener('click', (e) => {
            e.preventDefault();

            clearInterval(playInterval);

            framework.playInterval(els.dots[i].getAttribute('data-index'));
        });
    }
};

framework.popup = () => {
    els.downloadBtn.addEventListener('click', (e) => {
        e.preventDefault();

        ym(51610397, 'reachGoal', 'popup_opened');

        clearInterval(playInterval);
        document.querySelector('.page-bg__slide.active').querySelector('video').pause();

        els.popup.classList.add('active');
    });

    els.closeBtn.addEventListener('click', (e) => {
        e.preventDefault();

        if (!isMobile()) {
            document.querySelector('.page-bg__slide.active').querySelector('video').currentTime = 0;
            document.querySelector('.page-bg__slide.active').querySelector('video').play();
        }

        framework.playInterval(document.querySelector('.page-dots .active').getAttribute('data-index'));

        els.popup.classList.remove('active');
    });

    els.form.addEventListener('submit', (e) => {
        e.preventDefault();

        axios.post('/send', {
            email: els.form.querySelector('input').value
        })
            .then(function (response) {
                ym(51610397, 'reachGoal', 'form_send');
                els.form.style.display = 'none';
                document.querySelector('.popup-window__text').style.marginTop = '120px';
                document.querySelector('.popup-window__text').innerHTML = 'Письмо со&nbsp;ссылкой на&nbsp;обои отправлено!';
            });
    });
};

framework.playInterval = (active) => {
    if (document.querySelector('.page-devices__device.active')) {
        document.querySelector('.page-devices__device.active').classList.remove('active');
    }

    if (document.querySelector('.page-bg__slide.active')) {
        document.querySelector('.page-bg__slide.active').classList.remove('active');
    }

    if (document.querySelector('.page-dots .active')) {
        document.querySelector('.page-dots .active').classList.remove('active');
    }

    els.slides[active].classList.add('active');
    els.dots[active].classList.add('active');
    els.devices[active].classList.add('active');

    if (!isMobile()) {
        els.slides[active].querySelector('video').play();
    }

    playInterval = setInterval(() => {
        for (let i = 0; i < els.slides.length; i++) {
            if (els.slides[i].classList.contains('active')) {
                els.slides[i].classList.remove('active');

                // setTimeout(() => {
                // els.slides[i].querySelector('video').pause();
                // els.slides[i].querySelector('video').currentTime = 0;

                if (i + 1 < els.slides.length) {
                    if (!isMobile()) {
                        els.slides[i + 1].querySelector('video').play();
                    }
                    els.slides[i + 1].classList.add('active');
                } else {
                    if (!isMobile()) {
                        els.slides[0].querySelector('video').play();
                    }
                    els.slides[0].classList.add('active');
                }
                // }, 300);

                break;
            }
        }

        for (let i = 0; i < els.devices.length; i++) {
            if (els.devices[i].classList.contains('active')) {
                els.devices[i].classList.remove('active');

                if (i + 1 < els.devices.length) {
                    els.devices[i + 1].classList.add('active');
                } else {
                    els.devices[0].classList.add('active');
                }

                break;
            }
        }

        for (let i = 0; i < els.dots.length; i++) {
            if (els.dots[i].classList.contains('active')) {
                els.dots[i].classList.remove('active');

                if (i + 1 < els.dots.length) {
                    els.dots[i + 1].classList.add('active');
                } else {
                    els.dots[0].classList.add('active');
                }

                break;
            }
        }

    }, config.interval);
};

framework.autoplay = () => {
    if (!isMobile()) {
        els.slides[0].querySelector('video').play();
    }

    framework.playInterval(0);
};

document.addEventListener("DOMContentLoaded", () => {
    framework.init();
});